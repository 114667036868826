#bg__video{
  position: fixed;
  height: auto;
  width: 100vw;
  top: -75%;
}


h1 {
  width: 65%;
  max-width: 720px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 65px;
  line-height: 74.1px;
  font-weight: 700;
}

.main-container {
  position: relative;
  left: 10%;
  width: 80%;
  height: 100vh;
  padding-right: 0px;
  padding-left: 0px;
  float: left;
}

.navigation-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 15%;
  min-height: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  float: left;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.logo-box {
  position: relative;
  left: 5%;
  bottom: 0px;
  float: left;
}

.happy__wrapper {
  position: absolute;
  left: auto;
  top: auto;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  float: right;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.happy__overflow-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 130px;
  height: 100%;
  float: left;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  border-radius: 0px;
  background-color: transparent;
  white-space: nowrap;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.happy__emoji-box {
  float: left;
}

.image {
  width: 170px;
  min-width: 0px;
  float: left;
}

.happy__newyear {
  font-family: 'Suisseintl webm', sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.image-2 {
  margin-left: 10px;
}

.hero__main {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 45%;
  min-height: 0px;
  padding-top: 100px;
  float: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  /*background-color: #fff;*/
}

.heading {
  position: relative;
  left: 5%;
  top: auto;
  width: 65%;
  max-width: 720px;
  margin-top: 0px;
  font-size: 65px;
  line-height: 74px;
}

.text-block {
  width: 50%;
  float: left;
}

.chosse-txt {
  position: absolute;
  left: 10%;
  top: auto;
  bottom: -4em;
  width: 33%;
  max-width: 17ch;
  padding-top: 0em;
  font-family: 'Suisseintl webm', sans-serif;
  color: #787878;
  font-size: 1.8vw;
  line-height: 1.3;
  font-weight: 400;
}

.main__content {
  width: 100%;
  height: 40%;
  float: left;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
  background-image: url('../images/substi-mirror_img.png');
  background-position: 0% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.button__wrapper {
  position: absolute;
  right: 10%;
  width: 40%;
  max-width: 500px;
  min-width: 380px;
  float: left;
  -webkit-transform: translate(0px, -45px);
  -ms-transform: translate(0px, -45px);
  transform: translate(0px, -45px);
}

.button {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 125px;
  margin: 0px 0px 20px;
  padding: 2em 2em 0em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #f44433;
  text-decoration: none;
}

.button.browser-btn {
  height: 120px;
  max-height: 160px;
  padding-top: 1.5em;
  padding-bottom: 2em;
  padding-left: 1.5em;
  border-radius: 4px;
}

.button.instagram-btn {
  width: auto;
  height: 120px;
  max-height: none;
  margin-bottom: 0px;
  padding-top: 1.5em;
  padding-bottom: 2em;
  padding-left: 1.5em;
  border-radius: 4px;
}

.button__text-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  line-height: 26px;
  text-decoration: none;
}

.button__icon-wrap {
  position: absolute;
  right: 5%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-3 {
  font-size: 25px;
}

.browser-txt {
  font-family: 'Suisseintl webm', sans-serif;
  color: #fff;
  font-size: 1.8vw;
  line-height: 1.3;
  font-weight: 400;
  text-decoration: none;
}

.instagram-txt {
  font-family: 'Suisseintl webm', sans-serif;
  color: #fff;
  font-size: 1.8vw;
  line-height: 1.3;
  font-weight: 400;
  text-decoration: none;
}

.icon-browser {
  min-height: 2vw;
  min-width: 2vw;
}

.white-marge {
  width: 80%;
  height: 165px;
  float: left;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-color: #fff;
}

.heading-2 {
  position: relative;
  left: 5%;
  top: 0px;
  width: 70%;
  max-width: 17ch;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Suisseintl webm', sans-serif;
  font-size: 4vw;
  line-height: 1.3;
  font-weight: 500;
}

.recommended {
  font-size: 1.4vw;
}

.italic-text {
  font-size: 1.4vw;
}

@media screen and (min-width: 1280px) {
  .happy__overflow-wrap {
    border-top: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;
    border-radius: 0px;
    background-color: transparent;
  }

  .happy__newyear {
    line-height: 1;
  }

  .chosse-txt {
    width: 33%;
    max-width: 350px;
    color: #787878;
    font-size: 24px;
    line-height: 1.3;
  }

  .main__content {
    /*
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    */
  }

  .button__wrapper {
    min-width: 400px;
  }

  .browser-txt {
    font-size: 1.8vw;
    line-height: 1.3;
  }

  .instagram-txt {
    max-width: 25ch;
    font-size: 1.8vw;
    line-height: 1.3;
  }

  .heading-2 {
    max-width: 17ch;
    line-height: 1.3;
  }

  .recommended {
    font-size: 1.5vw;
  }

  .italic-text {
    font-size: 1.5vw;
  }
}

@media screen and (min-width: 1440px) {
  .happy__overflow-wrap {
    border-top: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;
    border-radius: 0px;
    background-color: transparent;
  }

  .happy__newyear {
    line-height: 1;
  }

  .chosse-txt {
    width: 33%;
    max-width: 400px;
    color: #787878;
  }

  .main__content {
    /*
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    */
  }

  .browser-txt {
    max-width: 25ch;
    font-size: 1.5vw;
    line-height: 1.3;
  }

  .instagram-txt {
    max-width: 25ch;
    font-size: 1.5vw;
    line-height: 1.3;
  }

  .heading-2 {
    width: 70%;
    max-width: 17ch;
  }

  .recommended {
    font-size: 1.2vw;
  }

  .italic-text {
    font-size: 1.3vw;
  }
}

@media screen and (min-width: 1920px) {
  .logo-box {
    max-width: 170px;
  }

  .happy__wrapper {
    height: 40px;
  }

  .happy__overflow-wrap {
    position: relative;
    right: -4px;
    border-top: 1px solid #cacaca;
    border-bottom: 1px solid #cacaca;
    border-radius: 0px;
    background-color: transparent;
  }

  .chosse-txt {
    width: 30%;
    color: #787878;
    font-size: 1.5vw;
    line-height: 1.3;
  }

  .button__wrapper {
    min-width: 400px;
  }

  .button.browser-btn {
    height: auto;
    max-height: 150px;
    padding-bottom: 2.5em;
    box-shadow: 0 8px 17px -4px rgba(244, 68, 51, 0);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .button.browser-btn:hover {
    box-shadow: 0 8px 17px -4px #f44433;
  }

  .button.browser-btn:active {
    background-color: #b22b1d;
    box-shadow: 0 8px 17px -4px rgba(244, 68, 51, 0);
  }

  .button.instagram-btn {
    height: auto;
    max-height: 150px;
    padding-bottom: 2.5em;
    box-shadow: 0 8px 14px -4px rgba(244, 68, 51, 0);
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .button.instagram-btn:hover {
    box-shadow: 0 8px 14px -4px #f44433;
  }

  .button.instagram-btn:active {
    background-color: #b22b1d;
    box-shadow: 0 8px 14px -4px rgba(244, 68, 51, 0);
  }

  .browser-txt {
    max-width: 25ch;
    font-size: 1.5vw;
    line-height: 1.3;
  }

  .instagram-txt {
    max-width: 25ch;
    font-size: 1.5vw;
    line-height: 1.3;
  }

  .white-marge {
    margin-right: 0px;
    margin-left: -1px;
  }

  .heading-2 {
    width: 70%;
    max-width: 17ch;
    line-height: 1.3;
  }

  .recommended {
    font-size: 1.1vw;
    letter-spacing: 1px;
    text-transform: none;
  }

  .italic-text {
    font-size: 1.1vw;
  }
}

@media screen and (max-width: 991px) {
  #bg__video{
    left: 0%;
    top: 0%;
  }
  .main-container {
    left: auto;
    width: 100%;
  }

  .navigation-container {
    left: 10%;
    width: 80%;
    height: 10%;
  }

  .logo-box {
    left: 0%;
  }

  .happy__wrapper {
    right: 10%;
  }

  .happy__overflow-wrap {
    width: 100px;
  }

  .image {
    width: 130px;
  }

  .happy__newyear {
    line-height: 1.3;
  }

  .hero__main {
    display: block;
    height: 45%;
    padding-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    top: 45%;
  }

  .chosse-txt {
    left: 10%;
    bottom: 30%;
    width: auto;
    margin-bottom: 70px;
    margin-left: 20px;
    padding-left: 0px;
    font-size: 20px;
    line-height: 1.3;
  }

  .main__content {
    height: 45%;
    border-right-style: none;
    border-left-style: none;
  }

  .button__wrapper {
    left: 10%;
    right: auto;
    width: 75%;
    max-width: none;
    -webkit-transform: translate(0px, -45px);
    -ms-transform: translate(0px, -45px);
    transform: translate(0px, -45px);
  }

  .button.browser-btn {
    height: auto;
    max-height: none;
    padding: 20px 20px 30px;
  }

  .button.instagram-btn {
    height: auto;
    padding: 20px 20px 30px;
  }

  .button__text-wrap {
    width: 62%;
  }

  .browser-txt {
    max-width: 25ch;
    font-size: 24px;
    line-height: 1.3;
  }

  .instagram-txt {
    max-width: 25ch;
    font-size: 24px;
    line-height: 1.3;
  }

  .white-marge {
    width: 73%;
    height: 68%;
  }

  .heading-2 {
    left: 5%;
    width: 80%;
    float: left;
    font-size: 5.5vw;
    line-height: 1.3;
  }

  .recommended {
    font-size: 18px;
  }

  .italic-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .main-container {
    height: auto;
  }

  .navigation-container {
    height: 70px;
  }

  .happy__wrapper {
    right: 0%;
  }

  .image {
    width: auto;
    height: 40px;
  }

  .hero__main {
    height: auto;
    padding-top: 0px;
    padding-bottom: 50px;
  }

  .chosse-txt {
    position: relative;
    width: 80%;
    max-width: 20ch;
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 20px;
    float: left;
  }

  .main__content {
    position: relative;
    padding-bottom: 40px;
    background-position: 0% 100%;
  }

  .button__wrapper {
    position: relative;
    z-index: 1;
    -webkit-transform: translate(0px, -20px);
    -ms-transform: translate(0px, -20px);
    transform: translate(0px, -20px);
  }

  .button__text-wrap {
    width: 70%;
  }

  .browser-txt {
    font-size: 24px;
    line-height: 1.3;
  }

  .instagram-txt {
    font-size: 24px;
    line-height: 1.3;
  }

  .white-marge {
    position: absolute;
    width: 80%;
    height: 70%;
  }

  .heading-2 {
    margin-top: 60px;
  }

  .recommended {
    font-size: 16px;
  }

  .italic-text {
    font-size: 16px;
  }
}

@media screen and (max-width: 479px) {
  #bg__video{
    left: 0%;
    top: 0;
  }
  .main-container {
    height: 100vh;
    max-height: 100vh;
  }

  .navigation-container {
    top: auto;
    display: block;
    width: 80%;
    height: 70px;
    max-height: none;
    margin-top: auto;
    padding-top: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    background-color: transparent;
  }

  .logo-box {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 36px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .happy__wrapper {
    right: 0%;
    height: 36px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .happy__overflow-wrap {
    position: relative;
    right: 19px;
    width: 66px;
    height: 25px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0px;
    background-color: transparent;
  }

  .happy__emoji-box {
    position: absolute;
    right: 0px;
    height: auto;
  }

  .image {
    width: auto;
    height: 20px;
  }

  .happy__newyear {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 25px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: -0.5px;
  }

  .image-2 {
    height: 36px;
  }

  .hero__main {
    height: auto;
    max-height: none;
    padding-top: 0px;
    padding-bottom: 50px;
    z-index: 2;
    top: 35%;
  }

  .chosse-txt {
    position: relative;
    left: 10%;
    top: auto;
    width: 80%;
    max-width: 20ch;
    margin-top: 20px;
    margin-bottom: auto;
    margin-left: 15px;
    padding-top: 0em;
    padding-bottom: 0em;
    padding-left: 0em;
    float: left;
    font-size: 16px;
    line-height: 1.3;
  }

  .main__content {
    height: 60vh;
  }

  .button__wrapper {
    left: 10%;
    right: 0px;
    width: 80%;
    max-width: none;
    min-width: 0px;
    -webkit-transform: translate(0px, -20px);
    -ms-transform: translate(0px, -20px);
    transform: translate(0px, -20px);
  }

  .button.browser-btn {
    width: auto;
    height: auto;
    max-height: none;
    margin-bottom: 15px;
    padding: 15px 15px 20px;
  }

  .button.instagram-btn {
    height: auto;
    padding: 15px 15px 20px;
  }

  .button__text-wrap {
    width: 80%;
  }

  .browser-txt {
    max-width: 18ch;
    font-size: 14px;
    line-height: 1.3;
  }

  .instagram-txt {
    width: auto;
    max-width: 20ch;
    font-size: 14px;
    line-height: 1.3;
  }

  .icon-in {
    max-width: 70%;
  }

  .icon-browser {
    max-width: 70%;
    min-height: 1.5vw;
    min-width: 1.5vw;
  }

  .white-marge {
    width: 80%;
    height: 60%;
  }

  .heading-2 {
    margin-top: 60px;
    font-size: 9vw;
    line-height: 1.3;
  }

  .recommended {
    font-size: 4vw;
  }

  .italic-text {
    font-size: 4vw;
  }
}

@font-face {
  font-family: 'Suisseintl webm';
  src: url('../fonts/SuisseIntl-Medium-WebM.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Suisseintl webm';
  src: url('../fonts/SuisseIntl-Bold-WebM.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Suisseintl webm';
  src: url('../fonts/SuisseIntl-Regular-WebM.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

#help__btn{
  position: fixed;
  bottom: 2%;
  z-index: 10;
  right: 2%;
  font-family: 'Suisseintl webm', sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #787878;
  font-size: 1.5vw;
  line-height: 1.3;
}
#help__btn:hover{
  cursor: pointer;
}

#popup{
  width: 20vw;
  height: auto;
  min-height: 20vh;
  position: fixed;
  z-index: 100;
  background: white;
  bottom: 10vh;
  right: 2vw;
  box-shadow: 0 8px 17px -4px #787878;
  border-radius: 4px;
  padding-top: 1.5em;
  padding-bottom: 2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  
}
#popup.hidden{
  display: none;
}
#popup.show{
  display: block;
}
#popup p{
  font-family: 'Suisseintl webm', sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 20px;
  color: #787878;
  position: relative;
  display: block;
  width: 100%;
}
#popup #close__btn{
  font-family: 'Suisseintl webm', sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-align: right;
  width: 100%;
  display: block;
  position: relative;
}
#popup #close__btn:hover{
  cursor: pointer;
}
#popup p b{
  font-family: 'Suisseintl webm', sans-serif;
  font-size: 20px;
  font-weight: 700;
}
#popup p a{
  color: #787878;
}


@media screen and (max-width: 991px){
  #help__btn{
    bottom: 13.5%;
  }
  #popup{
    width: 30vw;
    height: auto;
    min-height: 20vh;
    position: fixed;
    z-index: 100;
    background: white;
    bottom: 10vh;
    right: 2vw;
    box-shadow: 0 8px 17px -4px #787878;
    border-radius: 4px;
    padding-top: 1.5em;
    padding-bottom: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    
  }
}

@media screen and (max-width: 767px){
  #help__btn{
    bottom: 24%;
    font-size: 1em;
  }
}


@media screen and (max-width: 767px){
  #help__btn{
    bottom: 24%;
    font-size: 1em;
  }
  #popup {
    width: 60vw;
    height: auto;
    min-height: 20vh;
    position: fixed;
    z-index: 100;
    background: white;
    bottom: 10vh;
    right: 2vw;
    box-shadow: 0 8px 17px -4px #787878;
    border-radius: 4px;
    padding-top: 1.5em;
    padding-bottom: 2em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}
}

@media screen and (max-width: 479px){
  #help__btn{
    bottom: 4%;
    font-size: 1em;
  }
}
